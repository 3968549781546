import { defineMessages } from "react-intl";

export default defineMessages({
  Profile: { defaultMessage: "Profile" },
  Relays: { defaultMessage: "Relays" },
  Owner: { defaultMessage: "Owner" },
  Software: { defaultMessage: "Software" },
  Contact: { defaultMessage: "Contact" },
  Supports: { defaultMessage: "Supports" },
  Remove: { defaultMessage: "Remove" },
  Preferences: { defaultMessage: "Preferences" },
  Donate: { defaultMessage: "Donate" },
  LogOut: { defaultMessage: "Log Out" },
  Theme: { defaultMessage: "Theme" },
  System: { defaultMessage: "System (Default)" },
  Light: { defaultMessage: "Light" },
  Dark: { defaultMessage: "Dark" },
  DefaultRootTab: { defaultMessage: "Default Page" },
  Posts: { defaultMessage: "Posts" },
  Conversations: { defaultMessage: "Conversations" },
  Global: { defaultMessage: "Global" },
  AutoloadMedia: { defaultMessage: "Automatically load media" },
  AutoloadMediaHelp: {
    defaultMessage: "Media in posts will automatically be shown for selected people, otherwise only the link will show",
  },
  None: { defaultMessage: "None" },
  FollowsOnly: { defaultMessage: "Follows only" },
  All: { defaultMessage: "All" },
  ImgProxy: { defaultMessage: "Image proxy service" },
  ImgProxyHelp: { defaultMessage: "Use imgproxy to compress images" },
  ServiceUrl: { defaultMessage: "Service URL" },
  ServiceKey: { defaultMessage: "Key" },
  ServiceSalt: { defaultMessage: "Salt" },
  EnableReactions: { defaultMessage: "Enable reactions" },
  EnableReactionsHelp: {
    defaultMessage: "Reactions will be shown on every page, if disabled no reactions will be shown",
  },
  ConfirmReposts: { defaultMessage: "Confirm Reposts" },
  ConfirmRepostsHelp: { defaultMessage: "Reposts need to be manually confirmed" },
  ShowLatest: { defaultMessage: "Automatically show latest notes" },
  ShowLatestHelp: { defaultMessage: "Notes will stream in real time into global and posts tab" },
  FileUpload: { defaultMessage: "File upload service" },
  FileUploadHelp: { defaultMessage: "Pick which upload service you want to upload attachments to" },
  Default: { defaultMessage: "(Default)" },
  DebugMenus: { defaultMessage: "Debug Menus" },
  DebugMenusHelp: { defaultMessage: `Shows "Copy ID" and "Copy Event JSON" in the context menu on each message` },
  EditProfile: { defaultMessage: "Edit Profile" },
  About: { defaultMessage: "About" },
  LnAddress: { defaultMessage: "LN Address" },
  Avatar: { defaultMessage: "Avatar" },
  Banner: { defaultMessage: "Banner" },
  Edit: { defaultMessage: "Edit" },
  PrivateKey: { defaultMessage: "Your Private Key Is (do not share this with anyone)" },
  Add: { defaultMessage: "Add" },
  AddRelays: { defaultMessage: "Add Relays" },
  Name: { defaultMessage: "Name" },
  Website: { defaultMessage: "Website" },
  Save: { defaultMessage: "Save" },
  DisplayName: { defaultMessage: "Display name" },
  Buy: { defaultMessage: "Buy" },
  Nip05: { defaultMessage: "NIP-05" },
  ReactionEmoji: { defaultMessage: "Reaction emoji" },
  ReactionEmojiHelp: { defaultMessage: "Emoji to send when reactiong to a note" },
});
